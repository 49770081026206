<template>
	<div class="forward-body">
		<Tabs value="name1" @on-click="tabClick" style="background: #fff;">
			<TabPane label="设备列表" name="name1">
				<div class="search">
					<Form :label-width="80" label-colon inline>
						<FormItem label="设备ID">
							<Input v-model="search.device_id" placeholder="输入设备id用于搜索"></Input>
						</FormItem>
						<FormItem label="转发分组">
							<Select v-model="search.group_id" style="width:200px">
								<Option :value="0">全部</Option>
								<Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>
							</Select>
						</FormItem>
						<FormItem :label-width="0">
							<Button type="primary" @click="init()">搜索</Button>
						</FormItem>
						<FormItem :label-width="0">
							<Button @click="openEditModal()">新增</Button>
						</FormItem>
						<FormItem :label-width="0">
							<Button type="success" @click="openEditModal(true)">编辑</Button>
						</FormItem>
						<FormItem :label-width="0">
							<Button type="error" @click="deleteData()">删除</Button>
						</FormItem>
					</Form>
				</div>
				<div class="table">
					<Table :columns="columns" :data="data" :loading="loading" @on-selection-change="selectionChange">
						<template slot="is_forward" slot-scope="{row}">
							<Tag :color="row.is_forward == 1 ? 'success' : 'default'">{{ row.is_forward == 1 ? '是' : '否' }}</Tag>
						</template>
						<template slot="operate" slot-scope="{row}">
							<Button @click="toUrl('/device/forward_log',{ device_id: row.device_id })">日志</Button>
							<div style="display: inline-block; width: 6px;"></div>
							<Button @click="openDebugModal(row.device_id)">调试</Button>
						</template>
					</Table>
					<div class="ser-page right">
						<Page v-model="search.page" :total="search.total" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-total show-sizer transfer @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
					</div>
				</div>
			</TabPane>
			<TabPane label="分组列表" name="name2">
				<ForwardGrouping ref="ForwardGrouping"></ForwardGrouping>
			</TabPane>
		</Tabs>
		
		<Modal v-model="editModal.show" :title="editModal.status ? '新增配置' : '编辑配置'" @on-cancel="cancelEditModal()" width="800">
			<Form :label-width="120" label-colon style="padding-top: 32px; width: 90%; height: 600px">
				<FormItem label="设备id" v-show="editModal.status == true">
					<Input v-model="editModal.data.device_id" :disabled="true">
						<span slot="append" class="skip" @click="openDeviceModal()">搜索</span>
					</Input>
				</FormItem>
				<FormItem label="转发分组">
					<Select v-model="editModal.data.group_id">
						<Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>
					</Select>
				</FormItem>
			</Form>
			<template slot="footer">
				<Button @click="cancelEditModal()">取消</Button>
				<Button type="primary" @click="editModalConfirm()">确认</Button>
			</template>
		</Modal>
		
		<Modal v-model="debugModal.show" title="调试" >
			<div class="debug-list">
				<div class="debug-item">定位</div>
				<div class="debug-item"><span class="skip" @click="sendDebug('location')">发送</span></div>
				<div class="debug-item">心率</div>
				<div class="debug-item"><span class="skip" @click="sendDebug('heart')">发送</span></div>
				<div class="debug-item">体温</div>
				<div class="debug-item"><span class="skip" @click="sendDebug('temperature')">发送</span></div>
			</div>
			<template slot="footer">
				<Button @click="debugModal.show = false">关闭</Button>
			</template>
		</Modal>
		
		<Modal v-model="deviceModal.show" title="选择设备" width="800" @on-ok="selectDevice(1)" @on-cancel="cancelDeviceModal()">
			<Row style="margin-bottom: 16px;">
				<Col flex="100px"><Button icon="md-add" @click="selectDevice(0)">所有</Button></Col>
				<Col flex="240px"><DatePicker v-model="deviceModal.search.create_date" type="daterange" placement="bottom" placeholder="选择日期" style="width: 220px" @on-change="openDeviceModal()" /></Col>
				<Col style="display: flex; justify-content: end;" flex="auto">
					<Input v-model="deviceModal.search.imei" type="textarea" :autosize="true" placeholder="输入设备id搜索,支持多设备id(一行一个)"></Input>
				</Col>
				<Col flex="80px" style="text-align: right;">
					<Button @click="openDeviceModal()">搜索</Button>
				</Col>
			</Row>
			<Table :columns="deviceModal.columns" :data="deviceModal.data" :loading="deviceModal.loading" @on-selection-change="deviceSelectionChange" size="small" max-height="500"></Table>
			<div class="ser-page">
				<Page v-model="deviceModal.search.page" :total="deviceModal.search.total" :page-size="deviceModal.search.pageSize" :page-size-opts="[15,30,50,100,200]" show-total show-sizer transfer @on-change="devicePageChange" @on-page-size-change="devicePageSizeChange"></Page>
			</div>
		</Modal>
	</div>
</template>

<script>
	var _this, editData = {
		device_id: '',
		group_id: 0
	}, deviceSearch = {
		imei: '',
		device_type: [0,1,2],
		create_date: [],
		page: 1, //当前页码
		pageSize: 15, //每页条数
		total: 0 //总条数
	}
	import ForwardGrouping from './forward_grouping';
	export default {
		name: 'DeviceForward',
		components: { ForwardGrouping },
		data() {
			return {
				search: {
					device_id: '',
					group_id: 0,
					page: 1,
					pageSize: 15,
					total: 0
				},
				loading: false,
				columns: [
					{ type: 'selection', width: 60, align: 'center' },
					{ title: '设备id', key: 'device_id' },
					{ title: '转发分组', key: 'group_name', tooltip: true, width: 160 },
					{ title: '转发地址', key: 'forward_addr', tooltip: true },
					{ title: '是否转发', slot: 'is_forward', width: 100 },
					{ title: '备注', key: 'remarks', tooltip: true, width: 160 },
					{ title: '更新时间', key: 'update_date', width: 160 },
					{ title: '操作', slot: 'operate' }
				],
				data: [],
				selectData: [],
				editModal: {
					show: false,
					status: true,
					data: JSON.parse(JSON.stringify(editData))
				},
				deviceModal: {
					show: false,
					loading: false,
					columns: [
						{ type: 'selection', width: 60, align: 'center' },
						{ title: '设备id', key: 'imei' },
					],
					data: [],
					selectData: [],
					search: JSON.parse(JSON.stringify(deviceSearch))
				},
				debugModal: {
					show: false,
					device_id: ''
				},
				groupList: [] // 分组列表
			}
		},
		created() {
			_this = this;
			this.init();
		},
		methods: {
			init() {
				var _this = this;
				this.loading = true;
				this.requestApi('/adm/get_device_forward_list',{ search: this.search }).then((res) => {
					if(res.code == 200){
						_this.loading = false;
						_this.data = res.data.data;
						_this.search.total = res.data.total;
						_this.groupList = res.groupList;
					} else {
						_this.alertErr(res.msg);
					}
				});
			},
			selectionChange(data) {
				this.selectData = data;
			},
			// 切换页码
			pageChange(page) {
				this.search.page = page;
				this.init();
			},
			// 切换每页条数
			pageSizeChange(pageSize) {
				this.search.pageSize = pageSize;
				this.init();
			},
			// 新增/编辑 打开弹窗
			openEditModal(type = false){
				if(type){
					if(this.selectData.length == 0){
						return _this.alertErr('请选择要修改的数据');
					}
					for(let index in this.editModal.data){
						this.selectData.forEach((val, key) => {
							if(key == 0){
								this.editModal.data[index] = val[index];
							}else{
								if(this.editModal.data[index] != val[index]){
									this.editModal.data[index] = (index == 'is_forward' ? 1 : '');
								}
							}
						});
					}
					this.editModal.status = false;
					this.editModal.show = true;
				}else{
					this.editModal.show = true;
				}
			},
			// 新增/编辑 关闭弹窗
			cancelEditModal(){
				this.editModal.show = false;
				setTimeout(()=>{
					_this.editModal.status = true;
					_this.editModal.data = JSON.parse(JSON.stringify(editData));
				},500);
			},
			// 新增/编辑 确认
			editModalConfirm(){
				var data = JSON.parse(JSON.stringify(this.editModal.data));
				if(this.editModal.status == false){
					var device_ids = '';
					this.selectData.forEach((val) => {
						device_ids = device_ids ? device_ids+','+val.device_id : val.device_id;
					});
					data.device_id = device_ids;
				}
				// 是否选择分组
				if(! data.group_id) {
					return _this.alertErr('请选择分组');
				}
				this.requestApi('/adm/edit_device_forward_confirm',{ data: data }).then((res) => {
					if(res.code == 200){
						_this.alertSucc('保存成功');
						_this.cancelEditModal();
						_this.init();
					} else {
						_this.alertErr(res.msg);
					}
				});
			},
			// 打开选择设备弹窗
			openDeviceModal() {
				this.deviceModal.loading = true;
				let search_form = JSON.parse(JSON.stringify(this.deviceModal.search));
				
				search_form.imei = search_form.imei.replace(/\n/g, ",");
				console.log(search_form);
				this.requestApi('/adm/get_device_list',{ search_form }).then((res) => {
					if(res.code == 200){
						_this.deviceModal.show = true;
						_this.deviceModal.loading = false;
						_this.deviceModal.data = res.data.data.data;
						_this.deviceModal.search.total = res.data.data.total;
					} else {
						_this.alertErr(res.msg);
					}
				});
			},
			// 关闭 选择设备 弹窗
			cancelDeviceModal(){
				this.deviceModal.show = false;
				setTimeout(()=>{
					_this.deviceModal.selectData = [];
					_this.deviceModal.search = JSON.parse(JSON.stringify(deviceSearch));
				},500);
			},
			// 选择设备
			deviceSelectionChange(data){
				this.deviceModal.selectData = data;
			},
			// 设备弹窗 页数切换
			devicePageChange(page){
				this.deviceModal.search.page = page;
				this.openDeviceModal();
			},
			// 设备弹窗 每页条数切换
			devicePageSizeChange(pageSize){
				this.deviceModal.search.pageSize = pageSize;
				this.openDeviceModal();
			},
			// 确认选择设备
			selectDevice(index){
				var device_ids = ''
				if(index == 0){
					device_ids = '#all';
				} else {
					this.deviceModal.selectData.forEach((val) => {
						device_ids = device_ids ? device_ids+','+val.imei : val.imei;
					});
				}
				this.editModal.data.device_id = device_ids;
				// this.deviceModal.show = false;
				this.cancelDeviceModal();
			},
			// 打开 调试 弹窗
			openDebugModal(deviceId) {
				this.debugModal.show = true;
				this.debugModal.device_id = deviceId;
			},
			// 发送调试请求
			sendDebug(name) {
				console.log(name);
				this.requestApi('/adm/send_device_forward_debug',{ name: name, device_id: this.debugModal.device_id }).then((res) => {
					if(res.code == 200){
						_this.alertSucc('发送成功');
					} else {
						_this.alertErr(res.msg);
					}
				})
			},
			// 删除 转发配置
			deleteData() {
				if(this.selectData.length == 0){
					return _this.alertErr('请选择需要删除的数据');
				}
				this.$Modal.confirm({
					title: '确认删除',
					content: '确认删除所选设备的转发配置吗？删除配置会删除设备的所有转发记录，此操作将不可逆！',
					onOk: () => {
						var device_ids = '';
						_this.selectData.forEach((val) => {
							device_ids = device_ids ? device_ids+','+val.device_id : val.device_id;
						});
						_this.requestApi('/adm/del_device_forward', { device_ids: device_ids }).then((res) => {
							if(res.code == 200){
								_this.alertSucc('删除成功');
								_this.init();
							} else {
								_this.alertErr(res.msg);
							}
						});
					}
				});
			},
			// 切换 tab页
			tabClick(name) {
				if(name == 'name1') {
					this.init();
				}else if(name == 'name2') {
					this.$refs.ForwardGrouping.init();
				}
			}
		}
	}
</script>

<style scoped>
	.forward-body {
		background: #ffffff;
		padding: 16px 12px;
	}
	.forward-body .search {
		padding: 0 16px;
	}
	.forward-body .table{
		padding: 16px;
	}
	.skip {
		color: #337ab7;
		cursor: pointer;
	}
	.right{
		text-align: right;
	}
	.debug-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		border: 1px solid #e7eaec;
		border-bottom: none;
	}
	.debug-list .debug-item {
		padding: 8px;
		width: calc(50%);
		height: 40px;
		line-height: 24px;
		border-bottom: 1px solid #e7eaec;
		border-right: 1px solid #e7eaec;
	}
	
	.debug-list .debug-item:nth-child(2n){
		border-right: none;
	}
</style>
